import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import Helmet from "react-helmet";
import { MarkdownLayout } from '../../partials/MarkdownLayout';
import { Colors, Fonts } from "../../materials";
import { Label, LinkGatsby, Heading, Container, Box, LinkAnchor, Code, ButtonIcon, Input, List, ListItem, Note, Text, ImageStaticFluid } from "../../elements";
import { SnippetNavigationLandmarksAriaLabel } from '../../partials/SnippetNavigationLandmarksAriaLabel';
import { SnippetNavigationLandmarksAriaLabelledby } from '../../partials/SnippetNavigationLandmarksAriaLabelledby';
export const _frontmatter = {
  "seoTitle": "Multiple Navigation Landmarks - examples and best practices | Aditus",
  "seoURL": "https://www.aditus.io/patterns/multiple-navigation-landmarks",
  "seoImage": "https://www.aditus.io/social/patterns/multiple-navigation-landmarks.png",
  "seoImageAlt": "Text reading 'multiple navs' with the Aditus logo next to it",
  "title": "Multiple Navigation Landmarks",
  "description": "Learn how to make a website with multiple navigation landmarks accessible for people using screen readers.",
  "lead": ["When the navigation links of a webpage are split into multiple parts, such as a primary and a secondary navigation, we need to provide additional labelling for screen reader users.\n"],
  "toc": {
    "nodes": [{
      "selfId": "what-to-use",
      "selfText": "When to use"
    }, {
      "selfId": "expectations",
      "selfText": "Expectations",
      "nodes": [{
        "selfId": "keyboard-support",
        "selfText": "Keyboard Support"
      }, {
        "selfId": "aria-attributes",
        "selfText": "ARIA attributes"
      }]
    }, {
      "selfId": "examples",
      "selfText": "Examples",
      "nodes": [{
        "selfId": "example-1-using-aria-label-when-no-text-is-visible-on-screen",
        "selfText": "No visible text"
      }, {
        "selfId": "example-2-using-aria-labelledby-when-there-is-text-on-screen",
        "selfText": "Visible text"
      }]
    }, {
      "selfId": "related-wcag-success-criterion",
      "selfText": "Related SC"
    }]
  }
};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const TextBold = makeShortcode("TextBold");
const layoutProps = {
  _frontmatter
};

const MDXLayout = props => <MarkdownLayout {...props} renderAfter={() => <>
    <nav aria-labelledby='related-topics'>
      <Box mt={6} />
      <Box bg={Colors.oc.gray[0]} py={8}>
        <Container maxWidth='l'>
          <Heading id='related-topics'>Related Topics</Heading>
          <Box mt={6} />
          <List display='flex' flexWrap='wrap' m={-4}>
            <ListItem p={4} width={[1, 1 / 2, 1 / 3]}>
              <LinkGatsby to='/aria/aria-label/' css={{
              display: 'block',
              textDecoration: 'none',
              textAlign: 'left',
              '& > *': {
                backgroundColor: Colors.oc.gray[2],
                border: `5px solid ${Colors.oc.gray[3]}`
              },
              '&:hover > *': {
                backgroundColor: Colors.oc.blue[1],
                border: `5px solid ${Colors.oc.blue[9]}`
              }
            }}>
                <Box display='flex' alignItems='center' justifyContent='center' px={3} py={3} height={180}>
                  <Box display='flex' flexDirection='column'>
                    <Label as='span'>ARIA</Label>
                    <Text as='code' fontSize={5} css={{
                    fontFamily: Fonts.mono.family,
                    fontWeight: Fonts.mono.weight,
                    textDecoration: 'underline'
                  }}>
                      aria-label
                    </Text>
                  </Box>
                </Box>
              </LinkGatsby>
            </ListItem>
            <ListItem p={4} width={[1, 1 / 2, 1 / 3]}>
              <LinkGatsby to='/patterns/breadcrumbs/' css={{
              display: 'block',
              textDecoration: 'none',
              '& > *': {
                backgroundColor: Colors.oc.gray[2],
                border: `5px solid ${Colors.oc.gray[3]}`
              },
              '&:hover > *': {
                backgroundColor: Colors.oc.blue[0],
                border: `5px solid ${Colors.oc.blue[3]}`
              }
            }}>
                <Box display='flex' alignItems='center' justifyContent='center' px={3} py={3} height={180}>
                  <Box display='flex' flexDirection='column'>
                    <Label as='span'>Patterns</Label>
                    <Text as='span' fontSize={5} css={{
                    textDecoration: 'underline'
                  }}>
                      Breadcrumbs
                    </Text>
                  </Box>
                </Box>
              </LinkGatsby>
            </ListItem>
          </List>
        </Container>
      </Box> 
    </nav>
    </>} />;

export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <Box mt={8} mdxType="Box" />
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "590px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/20efc38b61cca29a105c57fb1fa05a09/7a7da/multiple-navigation-landmarks.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": ["noopener"]
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "67.11590296495956%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAANCAYAAACpUE5eAAAACXBIWXMAAAsSAAALEgHS3X78AAABzUlEQVQ4y5WTC1ObQBSF+f8/y6ltozGdsYpMGJFHNEgM7HsXiD29u9GYxLSdMnOW5S579rv3QlSUFdJpjPTbDaofKbKLO+TTBOVsjvwyQTXzsRjZJEb+PcYDaXEeoz5PkH+9RTG5C3H/blFUiJRxWNw8oDi7xeq6RD5JsJimaK4LlJdzrH6WyGhjScbS9VDa4dUM8JcVFnIcoAZSP0CSV8SEgl4JbNYapmaw3JBozjR0pyBbAScoRjLWhVjfCLxKB7Xs8NK0aJkA4wreK2JcwjyzcOpIJxqh4TYj3DBCE5F2juYDLMkRhaWYkxZOWfC6w+JijseXNbh4N6TBtJIMe/SMSLSF7bebNaWgjd0ZBTM/96TSoGsY6lmGhnN4MC41Ij/4jT5NS/XZUXhDe2j4btZTWUY7QCzWRJjiuWNHhNaFFI9JPhlS2oZqOVANf/UbDE8Mj18SPLXtB+HO0O1R/IuQGraxPUS1Rn2VYcW3TTk03KP7k2FQMKVmKQNGNfRNWRLh55TfGrGv8JmY02t2HINhfXWPhvHDlBVRhM178s+KOi716TX/OX0QdoeE3lmo/5B/32xTXhLh8mRTjmr4N4Vy0N1TdfQndR7qjfA36vvhANZhnsAAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "wireframe of a webpage with multiple navigation landmarks",
            "title": "wireframe of a webpage with multiple navigation landmarks",
            "src": "/static/20efc38b61cca29a105c57fb1fa05a09/7ba82/multiple-navigation-landmarks.png",
            "srcSet": ["/static/20efc38b61cca29a105c57fb1fa05a09/3e5d0/multiple-navigation-landmarks.png 148w", "/static/20efc38b61cca29a105c57fb1fa05a09/7ecfb/multiple-navigation-landmarks.png 295w", "/static/20efc38b61cca29a105c57fb1fa05a09/7ba82/multiple-navigation-landmarks.png 590w", "/static/20efc38b61cca29a105c57fb1fa05a09/7a7da/multiple-navigation-landmarks.png 742w"],
            "sizes": "(max-width: 590px) 100vw, 590px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <h2>{`When to use`}</h2>
    <Box mt={4} mdxType="Box" />
    <p>{`Landmarks help assistive technology (AT) users orient and navigate a webpage. The
image below shows a screenshot of VoiceOver on OSX when using landmark navigation:`}</p>
    <Box mt={6} mdxType="Box" />
    <Box display='flex' flexWrap='wrap' m={-6} mdxType="Box">
  <Box p={6} flex='1 1 auto' mdxType="Box">
    <Box border={`1px solid ${Colors.oc.gray[2]}`} mdxType="Box">
      <ImageStaticFluid path='articles/patterns/landmarks-vo-canada.png' alt='screenshot of voiceover landmark navigation' mdxType="ImageStaticFluid" />
    </Box>
    <Box mt={2} mdxType="Box" />
    <Text fontSize={2} color={Colors.oc.gray[7]} mdxType="Text">⛔ without labelling</Text>
  </Box>
  <Box p={6} flex='1 1 auto' mdxType="Box">
    <Box border={`1px solid ${Colors.oc.gray[2]}`} mdxType="Box">
      <ImageStaticFluid path='articles/patterns/landmarks-vo.png' alt='screenshot of voiceover landmark navigation' mdxType="ImageStaticFluid" />
    </Box>
    <Box mt={2} mdxType="Box" />
    <Text fontSize={2} color={Colors.oc.gray[7]} mdxType="Text">✅ with labelling</Text>
  </Box>
    </Box>
    <Box mt={8} mdxType="Box" />
    <p>{`When there is more than one landmark of the same type on the page (e.g., multiple `}<inlineCode parentName="p">{`<nav>`}</inlineCode>{`),
it's necessary to `}<strong parentName="p">{`provide additional labelling`}</strong>{`.`}</p>
    <Note title='Note:' mdxType="Note">
  <Box mt={1} mdxType="Box" />
  <Text mdxType="Text">On <strong>VoiceOver</strong>, you can navigate using landmarks by:</Text>
  <Box mt={3} mdxType="Box" />
  <List maxWidth='copy' ml={5} css={{
        listStyleType: 'decimal'
      }} mdxType="List">
    <ListItem mt={1} mdxType="ListItem">Turn VoiceOver on. <code>⌘ + F5</code></ListItem>
    <ListItem mt={1} mdxType="ListItem">Open the Rotor <code>CTRL + ⌥ + U</code></ListItem>
    <ListItem mt={1} mdxType="ListItem">Use the <code>←</code> and <code>→</code> arrow keys until you reach the "Landmark" list.</ListItem>
    <ListItem mt={1} mdxType="ListItem">Navigate the list using the <code>↑</code> and <code>↓</code> arrow keys.</ListItem>
    <ListItem mt={1} mdxType="ListItem">Press <code>↵</code> or <code>SPACE</code> to select to jump to the current item (rotor closes afterwards).</ListItem>
  </List>
    </Note>
    <Box mt={6} mdxType="Box" />
    <h2>{`Expectations`}</h2>
    <h3>{`Keyboard Support`}</h3>
    <Box mt={4} mdxType="Box" />
    <p>{`No keyboard interaction needed.`}</p>
    <h3>{`ARIA Attributes`}</h3>
    <Box mt={4} mdxType="Box" />
    <table aria-labelledby='aria-attributes'>
  <thead>
    <tr>
      <th>Attribute</th>
      <th>Element</th>
      <th>Notes</th>
      <th></th>
    </tr>
  </thead>
  <tbody>
    <tr>
      <td>
        <TextBold display={['inline', 'inline', 'none']} mdxType="TextBold">Attribute: </TextBold>
        <code>{`aria-label="breadcrumbs"`}</code>
      </td>
      <td>
        <TextBold display={['inline', 'inline', 'none']} mdxType="TextBold">Element: </TextBold>
        <code>{`<nav>`}</code>
      </td>
      <td>
        Describes the navigation to screen reader users (who navigate the page using landmarks)
      </td>
      <td>
        <LinkGatsby to='/aria/aria-label/' css={{
              whiteSpace: 'nowrap'
            }} mdxType="LinkGatsby">Learn more<span className='visually-hidden'> about <code>aria-label</code></span></LinkGatsby>
      </td>
    </tr>
  </tbody>
    </table>
    <Box mt={8} mdxType="Box" />
    <h2>{`Examples`}</h2>
    <h3>{`Example 1: using `}<inlineCode parentName="h3">{`aria-label`}</inlineCode>{` when no text is visible on screen`}</h3>
    <Box mt={6} mdxType="Box" />
    <SnippetNavigationLandmarksAriaLabel mdxType="SnippetNavigationLandmarksAriaLabel" />
    <Note title='Tip: Labelling best practice' mdxType="Note">
  <Text mdxType="Text">
    When using <code>aria-label</code> on a landmark element,
    don't include the word role of the landmark, as screen readers already
    annouce it afterwards.
  </Text>
  <Box mt={3} mdxType="Box" />
  <Code codeString={`// ⛔ BAD: "Primary navigation, navigation"
<nav aria-label="Primary navigation">...<nav>
  
// ✅ Good: "Primary, navigation"
<nav aria-label="Primary">...<nav>`} language='html' mdxType="Code" />
    </Note>
    <Box mt={10} mdxType="Box" />
    <h3>{`Example 2: using `}<inlineCode parentName="h3">{`aria-labelledby`}</inlineCode>{` when there is text on screen`}</h3>
    <Box mt={6} mdxType="Box" />
    <SnippetNavigationLandmarksAriaLabelledby mdxType="SnippetNavigationLandmarksAriaLabelledby" />
    <Box mt={6} mdxType="Box" />
    <h2>{`Related WCAG Success Criterion`}</h2>
    <Box mt={4} mdxType="Box" />
    <nav aria-labelledby='related-wcag-success-criterion'>
  <ul>
    <li>
      <LinkAnchor href='http://www.w3.org/TR/2008/REC-WCAG20-20081211/#content-structure-separation-programmatic' target="_blank" rel="noopener noreferrer" mdxType="LinkAnchor">
        1.3.1: Info and Relationships
      </LinkAnchor>
    </li>  
    </ul>
    </nav>


    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      